<template>
  <div class="main-top bgc-e8 color-f20" style="height: 100%">
    <div class="container">
      <div class="bgc_w pt-5 pb-5">
        <div class="w-75 m-auto fz-18">
          <div class="row justify-content-md-between">
            <div class="color-0">
              <span>当前账号: </span><span class="ml-2">{{ userInfo.phone_number }}</span>
            </div>
            <div class="color-0 fz-18">
              <router-link to="/BuyService" class="color-f20 price-detail"
                >价格说明</router-link
              >
            </div>
          </div>
          <div class="color-0 mt-lg-4">
            <span>账户类型&nbsp;: </span
            ><span class="ml-2">{{
              (userInfo.RoleNameSer && userInfo.RoleNameSer.role_name) || ""
            }}</span>
          </div>
          <div class="color-0 row mt-lg-4">
            <span>购买点数: </span>
            <div class="col">
              <div class="ml-2 row justify-content-between price-change">
                <router-link
                  v-for="(data, p) in priceArray"
                  :to="{
                    name: data.rName,
                    params: { price: data.price, point: data.point },
                  }"
                  :key="p"
                  class="d-inline-block p-2 border-bcgc ml-1"
                >
                  <span class="color-0">充值{{ data.point }}点/</span
                  ><span class="color-f49">{{ data.price }}元</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="color-0 mt-lg-4"><span>支付方式: </span></div>
          <div class="mt-lg-4 row">
            <span class="invisible"> 支付方式： </span>
            <div class="col">
              <div class="row">
                <div
                  class="pt-4 pb-4 pl-5 pr-5"
                  :class="isRadio == true ? 'border-le' : 'border-transparent'"
                >
                  <label for="alipay" class="cursor_pionter">
                    <span
                      class="btn-radio d-inline-block"
                      :class="isRadio == true ? 'bgc-lf' : ''"
                    ></span>
                    <input
                      type="radio"
                      name="pay"
                      @click="currentRadio"
                      id="alipay"
                      class="hide"
                    />
                    <img
                      src="../../../assets/img/personal/pay_alipay.png"
                      alt=""
                      class="ml-md-4"
                    />
                  </label>
                </div>
                <div
                  class="pt-4 pb-4 pl-5 pr-5"
                  :class="isRadio == true ? 'border-transparent' : 'border-le'"
                >
                  <label for="wechat" class="cursor_pionter">
                    <span
                      class="btn-radio d-inline-block cursor_pionter"
                      :class="isRadio == true ? '' : 'bgc-lf'"
                    ></span>
                    <input
                      type="radio"
                      name="pay"
                      @click="opRadio"
                      id="wechat"
                      class="hide"
                    />
                    <img
                      src="../../../assets/img/personal/pay_wechat.png"
                      alt=""
                      class="ml-md-4"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-lg-4 color-0 mt-lg-4 row">
            <span> 应付金额： </span>
            <div>
              <router-view class="ml-2 color-f49"></router-view>
            </div>
          </div>
          <div class="mt-lg-4 color-0 mt-lg-4 row">
            <span class="invisible"> 立即支付： </span>
            <div>
              <button type="button" class="btn btn-primary btn-md" @click="onPay">
                立即支付
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <i-modal
      v-model="showPayModal"
      ok-text="继续购买"
      cancel-text="放弃支付"
      @on-ok="onPay"
      @on-cancel="clearTime"
    >
      <div style="width: 150px; margin: 0 auto; font-size: 16px">
        <div style="margin-top: 10px">
          支付金额： <span v-html="$route.params.price"></span>
        </div>
        <div style="margin-top: 10px">支付状态：{{ pay_state }}</div>
        <div style="margin-top: 10px">
          支付方式： <span v-html="isRadio ? '支付宝' : '微信'"></span>
        </div>
      </div>
    </i-modal>
  </div>
</template>

<script>
import { alipay, wxpay, wx_payresult, userBasicInfo } from "@/api";
export default {
  data() {
    return {
      priceArray: [
        {
          rName: "FirstClass",
          point: "20",
          price: "20",
        },
        {
          rName: "SecondClass",
          point: "50",
          price: "50",
        },
        {
          rName: "ThirdClass",
          point: "200",
          price: "195",
        },
        {
          rName: "FourthClass",
          point: "1000",
          price: "950",
        },
      ],
      pay_state: "未支付",
      isRadio: true,
      isPaying: false,
      showPayModal: false,
      userInfo: {},
    };
    timer: null;
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      userBasicInfo().then((res) => {
        this.userInfo = res.data;
      });
    },
    onCheckOrders() {
      this.$router.push("/CenterIndex/OrderManage");
    },
    currentRadio() {
      this.isRadio = true;
    },
    opRadio() {
      this.isRadio = false;
    },
    clearTime() {
      clearTimeout(this.timer);
    },
    wx_payresult(order_num) {
      wx_payresult(order_num, this.isRadio).then((res) => {
        this.pay_state = +res.data.status ? "支付" : "未支付";
        if (+res.data.status) return false;
        this.timer = setTimeout(() => {
          this.wx_payresult(order_num);
        }, 1e3);
      });
    },

    onPay() {
      if (this.isRadio) {
        // 支付宝
        alipay({
          item_name: this.$route.params.point,
          item_discribe: "充值" + this.$route.params.point,
          price: this.$route.params.price,
          return_url:
            location.href.replace(this.$route.path, "") + "/CenterIndex/OrderManage",
        })
          .then(({ data }) => {
            console.info("alipay", data);
            if (data.status === 200) {
              window.open(data.data, "_blank");
              this.showPayModal = true;
              this.wx_payresult(data.order_num);
            }
          })
          .catch((err) => {
            this.$Message.error(JSON.stringify(err));
            this.showPayModal = true;
          });
      } else {
        //微信
        wxpay({
          item_name: "" + this.$route.params.point,
          item_discribe: "充值" + this.$route.params.point,
          price: this.$route.params.price,
          return_url:
            location.href.replace(this.$route.path, "") + "/CenterIndex/OrderManage",
        }).then((res) => {
          sessionStorage.wxpayData = JSON.stringify({
            rc: res.data.url,
            price: this.$route.params.price,
          });
          this.showPayModal = true;
          this.wx_payresult(res.data.order_num);

          window.open(`/#/wxpay`, "_blank");
        });
      }

      // if (this.isPaying) return
      // this.isPaying = true
      // alipay({
      //   item_name: this.$route.params.point,
      //   item_discribe: '充值' + this.$route.params.point,
      //   price: this.$route.params.price
      // }).then(resp => {
      //   // debugger
      //   this.isPaying = false
      //   console.info('alipay', resp)
      //   // this.$refs.pay.innerHTML = resp.html
      //   // this.$nextTick(() => {
      //   //   document.forms.alipaysubmit.submit()
      //   // })
      // }).catch(err => {

      // })
    },
  },
};
</script>

<style>
.page_head {
  background: url("../../../assets/img/search/bg_search.png") repeat center
    center;
  background-size: cover;
}
.price-detail {
  letter-spacing: 1px;
  border-bottom: 1px solid #ffd200;
}
.btn-radio {
  width: 16px;
  height: 16px;
  background-color: #ddd;
  border-radius: 50%;
}
.price-change .router-link-exact-active {
  position: relative;
  border: 1px solid #1172fe;
}
.price-change .router-link-exact-active::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 21px;
  height: 15px;
  background: url("../../../assets/img/personal/icon_select.png") no-repeat center center;
}
</style>
